export const StatusData = {
  STATUS_CONFIRM_MAIL: 0,
  STATUS_CERTIFICATION_PROGRESS: 1,
  STATUS_PRE_CERTIFIE: 1,
  STATUS_CERTIFIED: 3,
  STATUS_ARCHIVED: 98,
  STATUS_DISABLED: 99
}

export const getStatusIcon = (status) => {
  if (status < StatusData.STATUS_CERTIFIED) {
    return { name: 'warning', color: 'orange' }
  } else {
    return { name: 'certified', color: 'green' }
  }
}
