import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import moment from 'moment'
import { useAPI, useFile } from '@/composables'

moment.locale('fr')

const settingsDefault = {
  network: {},
  networks: []
}

export const useSettingsStore = defineStore('useSettingsStore', () => {
  const API = useAPI()
  const LogoFile = useFile()
  const CoverFile = useFile()
  const data = ref(settingsDefault)
  const files = ref({
    logo: null,
    cover: null,
    kbis: null
  })

  const getNetworks = computed(() => {
    return data.value.network
      ? Object.entries(data.value.network).reduce((acc, item) => {
        if (item[1] && item[1] !== '') {
          acc.push(item)
        }
        return acc
      }, [])
      : []
  })

  const getFormattedCreationDate = computed(() => {
    return data.value.createdAt ? moment(data.value.createdAt).format('LL') : false
  })

  const getFormattedUpdatedDate = computed(() => {
    return data.value.updatedAt ? moment(data.value.updatedAt).format('LL') : false
  })

  const getFormattedUpdatedHour = computed(() => {
    return data.value.updatedAt ? moment(data.value.updatedAt).format('LT') : false
  })

  const setData = (value) => {
    data.value = value
  }

  const fetchCreator = async () => {
    if (data.value._links && data.value._links.creator) {
      const response = await API.fetch(data.value._links.creator.href, { method: 'get' })
      data.value.creator = response.data
      return response
    }
  }

  const fetchEditor = async () => {
    if (data.value._links && data.value._links.editor) {
      const response = await API.fetch(data.value._links.editor.href, { method: 'get' })
      data.value.editor = response.data
      return response
    }
  }

  const fetch = async () => {
    try {
      const API = useAPI()
      const response = await API.fetch('/parameter', { method: 'get' })
      if (response) {
        setData(response.data)

        await fetchCreator()
        await fetchEditor()
      }
      return response
    } catch (e) {
      API.handleError(e)
    }
  }

  const fetchCountry = async ({ link, key }) => {
    if (link) {
      const response = await API.fetch(link, { method: 'get' })
      data.value[key].country = response.data
      return response
    }
  }

  const fetchAddress = async () => {
    if (data.value._links && data.value._links.address) {
      const response = await API.fetch(data.value._links.address.href, { method: 'get' })
      if (response.data) {
        data.value.address = response.data
        try {
          await fetchCountry({ link: response.data._links.country.href, key: 'address' })
        } catch (e) {
          console.error(e)
        }
      }
    }
  }

  const fetchHeadquarter = async () => {
    if (data.value._links && data.value._links.headQuarter) {
      const response = await API.fetch(data.value._links.headQuarter.href, { method: 'get' })
      if (response.data) {
        data.value.headquarter = response.data
        try {
          await fetchCountry({ link: response.data._links.country.href, key: 'headquarter' })
        } catch (e) {
          console.error(e)
        }
      }
      return response
    }
  }

  const fetchLogo = async () => {
    if (typeof data.value === 'object') {
      files.value.logo = null
      LogoFile.reset()
      if (data.value._links && data.value._links.logo) {
        const response = await LogoFile.fetch(data.value._links.logo.href)
        files.value.logo = response
        return response
      }
    }
  }

  const fetchCover = async () => {
    if (typeof data.value === 'object') {
      files.value.cover = null
      LogoFile.reset()
      if (data.value._links && data.value._links.cover) {
        const response = await CoverFile.fetch(data.value._links.cover.href)
        files.value.cover = response
        return response
      }
    }
  }

  const prepareUpdateData = (obj) => {
    const data = {
      companyName: obj.companyName,
      slogan: obj.slogan,
      description: obj.description,
      mail: obj.mail,
      businessName: obj.businessName,
      corporateName: obj.corporateName,
      activity: obj.activity,
      legalActivity: obj.legalActivity,
      codeNaf: obj.codeNaf,
      legalForm: obj.legalForm,
      commercialRegister: obj.commercialRegister,
      siren: obj.siren,
      siret: obj.siret,
      tvaNumber: obj.tvaNumber,
      phoneType: obj.phones[0] ? parseInt(obj.phones[0].type) : null,
      phoneAreaCode: obj.phones[0] ? obj.phones[0].areaCode : null,
      phoneNumber: obj.phones[0] ? obj.phones[0].number : null,
      altPhoneType: obj.phones[1] ? parseInt(obj.phones[1].type) : null,
      altPhoneAreaCode: obj.phones[1] ? obj.phones[1].areaCode : null,
      altPhoneNumber: obj.phones[1] ? obj.phones[1].number : null,
      capital: obj.capital ? obj.capital.toString() : null,
      network: obj.network
    }

    if (obj.address) {
      data.address = {
        id: obj.address.id,
        main: obj.address.main,
        additional: obj.address.additional,
        zipCode: obj.address.zipCode,
        city: obj.address.city,
        country: { id: obj.address.country.id }
      }
    }

    if (!obj.sameAddress && obj.headquarter) {
      data.headquarter = {
        id: obj.headquarter.id,
        main: obj.headquarter.main,
        additional: obj.headquarter.additional,
        zipCode: obj.headquarter.zipCode,
        city: obj.headquarter.city,
        country: { id: obj.headquarter.country.id }
      }
    }

    return data
  }

  const update = async (obj) => {
    if (!obj) { return }
    const data = prepareUpdateData(obj)
    return await API.fetch('/parameter', {
      method: 'put',
      data
    })
  }

  return {
    data,
    files,
    getNetworks,
    getFormattedCreationDate,
    getFormattedUpdatedDate,
    getFormattedUpdatedHour,
    fetchCreator,
    fetchEditor,
    fetch,
    fetchAddress,
    fetchHeadquarter,
    fetchLogo,
    fetchCover,
    update,
    LogoFile,
    CoverFile
  }
})
