import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import moment from 'moment'
import { useAPI, useCreateForm, useFile, useCommonObjDatas } from '@/composables'
import * as ProjectsData from '@/datas/projects'
import { LANGUAGES, ALPHABET_LIST } from '@/datas/globals'
import { useCustomerStore } from '@/store/customer'
import { useProjectsStore } from '@/store/projects'

const groupBy = require('object.groupby')

moment.locale('fr')

export const useProjectStore = defineStore('useProjectStore', () => {
  const API = useAPI()
  const LogoFile = useFile()
  const CommonObjDatas = useCommonObjDatas()
  const customerStore = useCustomerStore()
  const projectsStore = useProjectsStore()

  const project = ref({})
  const files = ref({
    logo: null
  })
  const history = ref([])
  const isLoaded = ref(false)

  const creationFields = {
    name: { value: '', required: true, isValid: false },
    customerName: { value: '', required: true, isValid: false },
    customerId: { value: '', required: false, isValid: false },
    type: 0,
    profil: { value: 1, required: false, isValid: false },
    commercialType: 0,
    about: { value: '', required: false, isValid: false },
    priority: 0,
    beginDate: { value: '', required: false, isValid: false },
    deliveryDate: { value: '', required: false, isValid: false }
  }

  const createForm = useCreateForm(creationFields)

  const emptyFieldsNumbers = computed(() => {
    return createForm.emptyFieldsNumbersDefault.value
  })

  const isDeployed = computed(() => {
    return false
  })

  const getTypeLabel = computed(() => {
    return typeof project.value.type === 'number' ? ProjectsData.TypesData[project.value.type].name || ProjectsData.TypesData[project.value.type].label : ''
  })

  const getPriorityLabel = computed(() => {
    return typeof project.value.priority === 'number' ? ProjectsData.PriorityData[project.value.priority].label : ''
  })

  const getCommercialTypeLabel = computed(() => {
    return typeof project.value.typeOrder === 'number' ? ProjectsData.CommercialTypeData[project.value.typeOrder].label : ''
  })

  const getDefaultLangLabel = computed(() => {
    return project.value.defaultLang ? LANGUAGES[project.value.defaultLang.toLowerCase()] : ''
  })

  const getCustomerId = computed(() => {
    if (!project.value._links) return
    const tmp = project.value._links.customer.href.split('/')
    return tmp[tmp.length - 1]
  })

  const getEditorFullName = computed(() => {
    return project.value.editor ? project.value.editor.firstName + ' ' + project.value.editor.lastName : ''
  })

  const getUpdatedInfo = computed(() => {
    return project.value.updatedAt
      ? 'Modifié le <span class="txt-regular">' + CommonObjDatas.getFormattedUpdatedDate.value + '</span> par <span class="txt-regular">' + getEditorFullName.value + '</span>'
      : 'Ce projet n’a jamais été modifié'
  })

  const create = async () => {
    if (emptyFieldsNumbers.value > 0) return false
    const fields = createForm.getCreateFields()
    const data = {
      name: fields.name.value,
      type: parseInt(fields.type),
      typeOrder: parseInt(fields.commercialType),
      description: fields.about.value,
      priority: parseInt(fields.priority),
      startingDate: fields.beginDate.value,
      deliveryDate: fields.deliveryDate.value,
      customer: {
        name: fields.customerName.value
      },
      package: {
        id: parseInt(fields.profil.value)
      }
    }

    if (fields.customerId.value) {
      data.customer.id = parseInt(fields.customerId.value)
    }

    return await API.fetch('/project', {
      method: 'post',
      data
    })
  }

  const update = async (obj) => {
    if (!obj) { return }
    const data = {
      name: obj.name,
      priority: parseInt(obj.priority),
      startingDate: obj.startingDate,
      deliveryDate: obj.deliveryDate,
      typeOrder: parseInt(obj.typeOrder),
      description: obj.description,
      domain: obj.domain,
      alias: obj.alias,
      expirationDate: obj.expirationDate,
      domainOwner: parseInt(obj.domainOwner),
      domainAdmin: parseInt(obj.domainAdmin),
      serverSmtpType: parseInt(obj.serverSmtpType),
      recaptchaSiteKey: obj.recaptchaSiteKey,
      recaptchaSecretKey: obj.recaptchaSecretKey,
      defaultLang: obj.defaultLang,
      projectFormatting: obj.projectFormatting
    }
    const response = await API.fetch('/project/' + project.value.id, {
      method: 'put',
      data
    })
    project.value = Object.assign(project.value, obj)
    return response
  }

  const remove = async () => {
    const response = await API.fetch('/project/' + project.value.id, {
      method: 'delete'
    })
    project.value = {}
    projectsStore.removeProject(project.value.id)
    return response
  }

  const fetch = async (id) => {
    try {
      const response = await API.fetch('/project/' + id, { method: 'get' })
      if (response) {
        project.value = response.data
        project.value.pages = [
          {
            id: 1,
            name: 'Accueil',
            ensemble: [
              {
                id: 'A',
                align: 0,
                organization: 0,
                distribution: 1,
                stick: 1,
                zones: [
                  {
                    col: [
                      {
                        name: 'Titre',
                        decli: '{Déclinaison}',
                        icon: 'component-title',
                        new: false,
                        deleting: false
                      },
                      {
                        name: 'Sous titre',
                        decli: '{Déclinaison}',
                        icon: 'component-subtitle',
                        new: false,
                        deleting: false
                      },
                      {
                        name: 'Espace libre',
                        decli: '32 pixels',
                        icon: 'component-free-32px',
                        new: false,
                        deleting: true
                      }
                    ]
                  }
                ]
              },
              {
                id: 'B',
                align: 0,
                organization: 1,
                distribution: 1,
                stick: 1,
                zones: [
                  {
                    col: [
                      {
                        name: 'Espace libre',
                        decli: '32 pixels',
                        icon: 'component-free-32px',
                        new: true,
                        deleting: false
                      }
                    ]
                  },
                  {
                    col: [
                      {
                        name: 'Espace libre',
                        decli: '32 pixels',
                        icon: 'component-free-32px',
                        new: true,
                        deleting: false
                      }
                    ]
                  }
                ]
              },
              {
                id: 'C',
                align: 0,
                organization: 2,
                distribution: 1,
                stick: 1,
                zones: [
                  {
                    col: [
                      {
                        name: 'Espace libre',
                        decli: '32 pixels',
                        icon: 'component-free-32px',
                        new: true,
                        deleting: false
                      }
                    ]
                  },
                  {
                    col: [
                      {
                        name: 'Espace libre',
                        decli: '32 pixels',
                        icon: 'component-free-32px',
                        new: true,
                        deleting: false
                      }
                    ]
                  },
                  {
                    col: [
                      {
                        name: 'Espace libre',
                        decli: '32 pixels',
                        icon: 'component-free-32px',
                        new: true,
                        deleting: false
                      }
                    ]
                  }
                ]
              },
              {
                id: 'D',
                align: 0,
                organization: 1,
                distribution: 0,
                stick: 1,
                zones: [
                  {
                    col: [
                      {
                        name: 'Espace libre',
                        decli: '24 pixels',
                        icon: 'component-free-24px',
                        new: false,
                        deleting: false
                      },
                      {
                        name: 'Espace libre',
                        decli: '32 pixels',
                        icon: 'component-free-32px',
                        new: false,
                        deleting: false
                      }
                    ]
                  },
                  {
                    col: [
                      {
                        name: 'Espace libre',
                        decli: '16 pixels',
                        icon: 'component-free-16px',
                        new: false,
                        deleting: false
                      }
                    ]
                  }
                ]
              },
              {
                id: 'E',
                align: 0,
                organization: 1,
                distribution: 2,
                stick: 1,
                zones: [
                  {
                    col: [
                      {
                        name: 'Espace libre',
                        decli: '64 pixels',
                        icon: 'component-free-64px',
                        new: false,
                        deleting: false
                      },
                      {
                        name: 'Espace libre',
                        decli: '32 pixels',
                        icon: 'component-free-32px',
                        new: false,
                        deleting: false
                      }
                    ]
                  },
                  {
                    col: [
                      {
                        name: 'Espace libre',
                        decli: '4 pixels',
                        icon: 'component-free-4px',
                        new: false,
                        deleting: false
                      },
                      {
                        name: 'Espace libre',
                        decli: '8 pixels',
                        icon: 'component-free-8px',
                        new: false,
                        deleting: false
                      }
                    ]
                  }
                ]
              },
              {
                id: 'F',
                align: 0,
                organization: 1,
                distribution: 0,
                stick: 0,
                zones: [
                  {
                    col: [
                      {
                        name: 'Espace libre',
                        decli: '64 pixels',
                        icon: 'component-free-64px',
                        new: false,
                        deleting: false
                      }
                    ]
                  },
                  {
                    col: [
                      {
                        name: 'Espace libre',
                        decli: '4 pixels',
                        icon: 'component-free-4px',
                        new: false,
                        deleting: false
                      },
                      {
                        name: 'Espace libre',
                        decli: '8 pixels',
                        icon: 'component-free-8px',
                        new: false,
                        deleting: false
                      }
                    ]
                  }
                ]
              },
              {
                id: 'G',
                align: 0,
                organization: 1,
                distribution: 2,
                stick: 2,
                zones: [
                  {
                    col: [
                      {
                        name: 'Espace libre',
                        decli: '64 pixels',
                        icon: 'component-free-64px',
                        new: false,
                        deleting: false
                      },
                      {
                        name: 'Espace libre',
                        decli: '32 pixels',
                        icon: 'component-free-32px',
                        new: false,
                        deleting: false
                      }
                    ]
                  },
                  {
                    col: [
                      {
                        name: 'Espace libre',
                        decli: '4 pixels',
                        icon: 'component-free-4px',
                        new: false,
                        deleting: false
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]

        if (project.value._links.logo) {
          files.value.logo = await LogoFile.fetch(project.value._links.logo.href)
        } else {
          LogoFile.reset()
        }
        CommonObjDatas.setData(response.data)
        project.value.creator = await CommonObjDatas.fetchCreator()
        project.value.editor = await CommonObjDatas.fetchEditor()
        isLoaded.value = true
      }
      return response
    } catch (e) {
      API.handleError(e)
    }
  }

  const fetchCustomer = async () => {
    try {
      const currentCustomerHref = customerStore.customer && customerStore.customer.id ? customerStore.customer._links.self.href : false
      if (!currentCustomerHref || project.value._links.customer.href !== customerStore.customer._links.self.href) {
        await customerStore.fetch(getCustomerId.value)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const fetchHistory = async () => {
    if (project.value.id) {
      const response = await API.fetch('/history/project/' + project.value.id, {
        method: 'post',
        data: {
          filters: {
            page: 0,
            limit: 10
          }
        }
      })
      history.value = response.data
      return response
    }
  }

  const deleteLogo = async () => {
    if (files.value.logo) {
      return await LogoFile.remove()
    }
  }

  const reset = () => {
    project.value = {}
    history.value = {}
    LogoFile.reset()
    customerStore.reset()
  }

  const getGroupedHistory = computed(() => {
    const mapped = history.value.map((item) => {
      item.date = moment(item.date).format('LL')
      return item
    })

    const groupByDate = groupBy(mapped, ({ date }) => date)

    Object.keys(groupByDate).forEach((item) => {
      const groupByDomain = groupBy(groupByDate[item], ({ domain }) => domain)
      const withoutDuplicated = Object.entries(groupByDomain).reduce((acc, [key, values]) => {
        acc[key] = values.reduce((acc, item) => {
          const displayedText = ProjectsData.HistoryData[item.field]
          if (acc.indexOf(displayedText) === -1) {
            acc.push(displayedText)
          }
          return acc
        }, [])
        return acc
      }, {})

      groupByDate[item] = withoutDuplicated
    })

    return groupByDate
  })

  const getMenuType = computed(() => {
    if (project.value.projectFormatting) {
      const id = typeof project.value.projectFormatting.menu.mode === 'number' ? project.value.projectFormatting.menu.mode : 0
      const filtered = ProjectsData.FormattingData.menuTypes.filter((item) => item.value === id)
      return filtered.length > 0 ? filtered[0] : ProjectsData.FormattingData.menuTypes[0]
    } else {
      return ProjectsData.FormattingData.menuTypes[0]
    }
  })

  const getSubMenuType = computed(() => {
    if (project.value.projectFormatting) {
      const id = typeof project.value.projectFormatting.menu.type === 'number' ? project.value.projectFormatting.menu.type : 0
      const filtered = ProjectsData.FormattingData.subMenuTypes.filter((item) => item.value === id)
      return filtered.length > 0 ? filtered[0] : ProjectsData.FormattingData.subMenuTypes[0]
    } else {
      return ProjectsData.FormattingData.subMenuTypes[0]
    }
  })

  const getServicesNavType = computed(() => {
    if (project.value.projectFormatting) {
      const id = typeof project.value.projectFormatting.services === 'number' ? project.value.projectFormatting.services : 0
      const filtered = ProjectsData.FormattingData.servicesNavTypes.filter((item) => item.value === id)
      return filtered.length > 0 ? filtered[0] : ProjectsData.FormattingData.servicesNavTypes[0]
    } else {
      return ProjectsData.FormattingData.servicesNavTypes[0]
    }
  })

  const getFooterType = computed(() => {
    if (project.value.projectFormatting) {
      const id = typeof project.value.projectFormatting.footer.mode === 'number' ? project.value.projectFormatting.footer.mode : 0
      const filtered = ProjectsData.FormattingData.footerTypes.filter((item) => item.value === id)
      return filtered.length > 0 ? filtered[0] : ProjectsData.FormattingData.footerTypes[0]
    } else {
      return ProjectsData.FormattingData.footerTypes[0]
    }
  })

  const getPage = computed(() => (id) => {
    const ff = (item) => {
      return item.id === parseInt(id)
    }
    const result = project.value.pages.filter(ff)
    return result.length ? result[0] : null
  })

  const getEnsemble = computed(() => (ensembleId, pageId) => {
    const result = getPage.value(pageId).ensemble.filter((item) => item.id === ensembleId)
    return result.length ? result[0] : null
  })

  const getZonesLength = computed(() => (ensemble) => {
    return ensemble.zones.reduce((acc, item) => {
      acc += item.col.length
      return acc
    }, 0)
  })

  const deleteEnsemblePage = (ensembleId, pageId) => {
    const page = getPage.value(pageId)
    const index = page.ensemble.findIndex((element) => element.id === ensembleId)
    page.ensemble.splice(index, 1)
    page.ensemble = reorganizeEnsemblesId(page.ensemble)
    return page.ensemble
  }

  const reorganizeEnsemblesId = (ensembles) => {
    return ensembles.map((element, i) => {
      element.id = ALPHABET_LIST[i]
      return element
    })
  }

  return {
    ...createForm,
    ...CommonObjDatas,
    emptyFieldsNumbers,
    isDeployed,
    isLoaded,
    getTypeLabel,
    getPriorityLabel,
    getCommercialTypeLabel,
    getDefaultLangLabel,
    getUpdatedInfo,
    getCustomerId,
    getGroupedHistory,
    getMenuType,
    getSubMenuType,
    getServicesNavType,
    getFooterType,
    getPage,
    getEnsemble,
    getZonesLength,
    deleteEnsemblePage,
    create,
    update,
    remove,
    fetch,
    fetchCustomer,
    fetchHistory,
    deleteLogo,
    reset,
    project,
    history,
    files,
    LogoFile
  }
})
