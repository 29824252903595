import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import moment from 'moment'
import { useAPI, useListWithCheck } from '@/composables'
import { StatusData } from '@/datas/customers/status'
import { COMPANY_TYPE, PUBLIC_SERVICE_TYPE, ASSOCIATION_TYPE, PRIVATE_PEOPLE_TYPE } from '@/datas/customers/types'

moment.locale('fr')

export const useCustomersStore = defineStore('useCustomersStore', () => {
  const listWithCheck = useListWithCheck()
  const lastUpdateUser = ref(null)

  const recentlyAddedCustomers = computed(() => {
    return listWithCheck.allItems.value.filter((item) => moment(item.createdAt).format('MMM Do YY') === moment().format('MMM Do YY'))
  })

  const recentlyEditedCustomers = computed(() => {
    return listWithCheck.allItems.value.filter((item) => moment(item.updatedAt).format('MMM Do YY') === moment().format('MMM Do YY'))
  })

  const archivedCustomers = computed(() => {
    return listWithCheck.allItems.value.filter((item) => item.status === StatusData.STATUS_DISABLED)
  })

  const certifiedCustomers = computed(() => {
    return listWithCheck.allItems.value.filter((item) => item.status === StatusData.STATUS_CERTIFIED)
  })

  const suspendedCustomers = computed(() => {
    return listWithCheck.allItems.value.filter((item) => item.status === StatusData.STATUS_DISABLED)
  })

  const companyCustomers = computed(() => {
    return listWithCheck.allItems.value.filter((item) => item.type === COMPANY_TYPE)
  })

  const publicCustomers = computed(() => {
    return listWithCheck.allItems.value.filter((item) => item.type === PUBLIC_SERVICE_TYPE)
  })

  const associationCustomers = computed(() => {
    return listWithCheck.allItems.value.filter((item) => item.type === ASSOCIATION_TYPE)
  })

  const privatePeopleCustomers = computed(() => {
    return listWithCheck.allItems.value.filter((item) => item.type === PRIVATE_PEOPLE_TYPE)
  })

  const getCertifiedNumber = computed(() => {
    return certifiedCustomers.value.length
  })

  const getInProgressNumber = computed(() => {
    return listWithCheck.allItems.value.length
  })

  const getSuspendedNumber = computed(() => {
    return suspendedCustomers.value.length
  })

  const getLastUpdated = computed(() => {
    const tmp = listWithCheck.allItems.value.map((item) => item)
    const sortedArray = tmp.sort((a, b) => {
      const timeA = a.updatedAt ? moment(a.updatedAt).valueOf() : 0
      const timeB = b.updatedAt ? moment(b.updatedAt).valueOf() : 0
      if (timeA > timeB) {
        return -1
      }
      if (timeA < timeB) {
        return 1
      }
      return 0
    })
    return sortedArray.length ? sortedArray[0] : null
  })

  const searchByName = (name) => {
    const regex = new RegExp('^' + name.toLowerCase(), 'g')
    return listWithCheck.list.value.reduce((acc, item) => {
      if (item.name.toLowerCase().match(regex)) {
        acc.push(item)
      }
      return acc
    }, [])
  }

  const setList = listWithCheck.setCheckList

  const setLogo = (url, id) => {
    listWithCheck.list.value.forEach((item) => {
      if (item.id === id) {
        item.logo = url
      }
    })
  }

  const setName = (name, id) => {
    const item = listWithCheck.getItemById(id)
    if (item) {
      item.name = name
    }
  }

  const fetch = async () => {
    const API = useAPI()
    const response = await API.fetch('/customers', { method: 'get' })
    if (response) {
      listWithCheck.setCheckList(response.data)
    }
    return response
  }

  const fetchUserLastUpdate = async () => {
    if (getLastUpdated.value._links && getLastUpdated.value._links.editor) {
      const API = useAPI()
      const response = await API.fetch(getLastUpdated.value._links.editor.href, { method: 'get' })
      lastUpdateUser.value = response.data
      return response
    }
  }

  return {
    ...listWithCheck,
    lastUpdateUser,
    recentlyAddedCustomers,
    recentlyEditedCustomers,
    archivedCustomers,
    certifiedCustomers,
    companyCustomers,
    publicCustomers,
    associationCustomers,
    privatePeopleCustomers,
    getCertifiedNumber,
    getInProgressNumber,
    getSuspendedNumber,
    getLastUpdated,
    searchByName,
    setList,
    setLogo,
    setName,
    fetch,
    fetchUserLastUpdate
  }
})
