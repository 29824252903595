export const mainModel = [
  { label: 'Vertical scrolling pages', value: '0', icon: { name: 'tpl-vertical-scrolling' } },
  { label: 'Vertical single frame', value: '1', icon: { name: 'tpl-vertical-single-frame' } },
  { label: 'Horizontal scrolling pages', value: '2', icon: { name: 'tpl-horizontal-scrolling' } },
  { label: 'Horizontal single frame', value: '3', icon: { name: 'tpl-horizontal-single-frame' } }
]

export const menuPositions = [
  { label: 'En entête', value: '0', icon: { name: 'menu-header' } },
  { label: 'À gauche', value: '1', icon: { name: 'menu-left' } },
  { label: 'À droite', value: '2', icon: { name: 'menu-right' } },
  { label: 'En pied', value: '3', icon: { name: 'menu-footer' } }
]

export const menuScrollBehaviors = [
  { label: 'Libre', value: '0', icon: { name: 'scroll-free' } },
  { label: 'Masquer', value: '1', icon: { name: 'scroll-hide-menu' } },
  { label: 'Réduction', value: '2', icon: { name: 'scroll-menu-min' } },
  { label: 'Fixe', value: '3', icon: { name: 'scroll-menu-fixed' } }
]

export const menuTypes = [
  {
    value: 0,
    title: 'Menu texte ferré à droite',
    height: '88px',
    icon: { name: 'header-01' },
    desc: 'Logotype ferré à gauche et liste des rubriques au format texte alignés à droite …'
  },
  {
    value: 1,
    title: 'Logotype et menu texte centré',
    height: '88px',
    icon: { name: 'header-02' },
    desc: ''
  },
  {
    value: 2,
    title: 'Menu texte au centre',
    height: '88px',
    icon: { name: 'header-03' },
    desc: ''
  },
  {
    value: 3,
    title: 'Burger menu à gauche',
    height: '88px',
    icon: { name: 'header-04' },
    desc: ''
  },
  {
    value: 4,
    title: 'Burger menu à droite',
    height: '88px',
    icon: { name: 'header-05' },
    desc: ''
  },
  {
    value: 5,
    title: 'Burger menu à droite v2',
    height: '88px',
    icon: { name: 'header-06' },
    desc: ''
  }
]

export const subMenuTypes = [
  { value: 0, title: 'Sous-menu in Line 01', icon: { name: 'submenu-01' }, desc: 'Liste des sous-rubrique (sous-menu texte) au centre.' },
  { value: 1, title: 'Sous-menu in Line 02', icon: { name: 'submenu-02' }, desc: '' },
  { value: 2, title: 'Sous-menu in Line 03', icon: { name: 'submenu-03' }, desc: '' },
  { value: 3, title: 'Sous-menu in Line 04', icon: { name: 'submenu-04' }, desc: '' },
  { value: 4, title: 'Sous-menu in Line 05', icon: { name: 'submenu-05' }, desc: '' }
]

export const servicesNavTypes = [
  { value: 0, title: 'Services Nav Classic 01', icon: { name: 'nav-service-01' }, desc: 'Titre et slogan ferrés à gauche, liste des réseaux sociaux au centre, informations de contact ferrés à droite.' },
  { value: 1, title: 'Services Nav Classic 02', icon: { name: 'nav-service-02' }, desc: '' },
  { value: 2, title: 'Services Nav Classic 03', icon: { name: 'nav-service-03' }, desc: '' },
  { value: 3, title: 'Services Nav Classic 04', icon: { name: 'nav-service-04' }, desc: '' },
  { value: 4, title: 'Services Nav Classic 05', icon: { name: 'nav-service-05' }, desc: '' }
]

export const footerTypes = [
  { value: 0, title: 'Footer Classic Horizontal', icon: { name: 'footer-horizontal' }, desc: 'Présentation sobre des différents éléments de contenu sous la forme de modules disposés à l’horizontale.' },
  { value: 1, title: 'Footer Classic Vertical', icon: { name: 'footer-vertical' }, desc: 'Présentation sobre des différents éléments de contenu sous la forme de modules disposés à la verticale.' }
]

export const footerOptions = [
  {
    id: 'labels',
    label: 'Labels, partenaires & réassurance',
    value: false,
    disabled: false
  },
  {
    id: 'newsletter',
    label: 'Inscription à la newsletter',
    value: false,
    disabled: false
  },
  {
    id: 'geolocation',
    label: 'Géolocalisation & infos pratiques',
    value: false,
    disabled: false
  },
  {
    id: 'schedule',
    label: 'Horaires d’ouvertures',
    value: false,
    disabled: false
  },
  {
    id: 'ariane',
    label: 'Fil d’Ariane',
    value: false,
    disabled: false
  },
  {
    id: 'information',
    label: 'Information & coordonnées',
    value: true,
    disabled: false
  },
  {
    id: 'plan',
    label: 'Plan du site',
    value: true,
    disabled: false
  },
  {
    id: 'socialNetwork',
    label: 'Réseau sociaux',
    value: true,
    disabled: false
  },
  {
    id: 'legalNotice',
    label: 'Mentions légales, langues et Copyright',
    value: true,
    disabled: true
  }
]
