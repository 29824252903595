export const ROUTE_BASE = '/settings'
export const ROUTE_MY_PROFILE = 'my-profile'
export const ROUTE_GENERAL = 'general'
export const ROUTE_PRESENTATION_EDIT = 'presentation'
export const ROUTE_CONTACT_DETAILS_EDIT = 'contact-details'
export const ROUTE_CONTACT_PEOPLES_EDIT = 'contact-peoples'
export const ROUTE_SERVICES_STATE_EDIT = 'services-state'
export const ROUTE_LEGALS_DATA = 'legals-data'
export const ROUTE_LEGALS_DATA_REFS_EDIT = 'reference'
export const ROUTE_LEGALS_DATA_REPRESENTATION_EDIT = 'representation'
export const ROUTE_COLLABORATORS = 'collaborators'
export const ROUTE_TEAM = 'team'
export const ROUTE_SYSTEM = 'system'
export const ROUTE_APPEARANCE = 'appearance'
export const ROUTE_API = 'api'
export const ROUTE_CONTRACT_BILLING = 'contract-billing'
export const ROUTE_LEGALS_MENTION = 'legals-mentions'

export default {
  ROUTE_BASE,
  ROUTE_GENERAL,
  ROUTE_CONTACT_DETAILS_EDIT,
  ROUTE_CONTACT_PEOPLES_EDIT,
  ROUTE_SERVICES_STATE_EDIT,
  ROUTE_LEGALS_DATA,
  ROUTE_COLLABORATORS,
  ROUTE_TEAM,
  ROUTE_SYSTEM,
  ROUTE_APPEARANCE,
  ROUTE_API,
  ROUTE_CONTRACT_BILLING,
  ROUTE_LEGALS_MENTION
}
