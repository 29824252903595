<script setup>
import { defineProps, defineEmits, onMounted, ref } from 'vue'
import useAPI from '@/composables/useApi'

const props = defineProps({
  apiUrl: String,
  url: String,
  shape: {
    type: String,
    default: 'circle'
  }
})

const emit = defineEmits(['onLoaded'])
const srcUrl = ref('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=')
const isLoaded = ref(false)
onMounted(async () => {
  if (!props.url && props.apiUrl) {
    const API = useAPI()
    const response = await API.fetch(props.apiUrl, { method: 'get' })
    if (response && response.data && response.data.name) {
      srcUrl.value = process.env.VUE_APP_MEDIAS_URL + '/' + response.data.domain + response.data.name + '.' + response.data.extension
      emit('onLoaded', srcUrl.value)
      isLoaded.value = true
    }
  } else {
    isLoaded.value = true
  }
})
</script>

<template>
  <img :src="url || srcUrl" alt="" class="nbs-logo" :class="['nbs-logo--' + props.shape, { 'nbs-logo--loaded': isLoaded }]">
</template>

<style lang="scss">
.nbs-logo {
  opacity: 0;

  &--circle {
    border-radius: 50%;
  }

  &--rounded {
    border-radius: rem(4px);
  }

  &--loaded {
    opacity: 1;
  }
}
</style>
