import { ref, computed } from 'vue'
import moment from 'moment'
import { useAPI } from './useApi.js'

moment.locale('fr')

export const useCommonObjDatas = () => {
  const datas = ref({})
  const API = useAPI()

  const getNetworks = computed(() => {
    return datas.value.network
      ? Object.entries(datas.value.network).reduce((acc, item) => {
        if (item[1] && item[1] !== '') {
          acc.push(item)
        }
        return acc
      }, [])
      : []
  })

  const getFormattedCreationDate = computed(() => {
    return moment(datas.value.createdAt).format('LL')
  })

  const getFormattedUpdatedDate = computed(() => {
    return moment(datas.value.updatedAt).format('LL')
  })

  const setData = (value) => {
    datas.value = value
  }

  const fetchCreator = async () => {
    if (datas.value._links && datas.value._links.creator) {
      const response = await API.fetch(datas.value._links.creator.href, { method: 'get' })
      return response.data
    }
  }

  const fetchEditor = async () => {
    if (datas.value._links && datas.value._links.editor) {
      const response = await API.fetch(datas.value._links.editor.href, { method: 'get' })
      return response.data
    }
  }

  return {
    getNetworks,
    getFormattedCreationDate,
    getFormattedUpdatedDate,
    setData,
    fetchCreator,
    fetchEditor
  }
}
