import { computed, isRef, ref } from 'vue'

export const useListWithCheck = () => {
  const list = ref([])
  const checkedNb = computed(() => {
    return allItems.value.reduce((acc, item) => {
      if (item.checked) {
        acc.push(item)
      }
      return acc
    }, []).length
  })

  const getListNumber = computed(() => allItems.value.length)

  const allItems = computed(() => {
    return list.value.reduce((acc, item) => {
      if (item.items) {
        item.items.forEach(element => {
          acc.push(element)
        })
      } else {
        acc.push(item)
      }
      return acc
    }, [])
  })

  const getItemById = (id) => {
    return allItems.value.filter((item) => item.id === id)[0]
  }

  const removeItem = (id) => {
    let index = list.value.findIndex((item) => item.id === id)
    if (index > -1) {
      list.value.splice(index, 1)
    } else {
      list.value.forEach((item) => {
        if (item.items) {
          index = item.items.findIndex((el) => el.id === id)
          if (index > -1) {
            item.items.splice(index, 1)
          }
        }
      })
    }
  }

  const _mapChecked = (item) => {
    item.checked = false

    if (item.items) {
      item.items = item.items.map((item) => {
        item.checked = false
        return item
      })
    }
    return item
  }

  const resetChecked = () => {
    if (list.value.length) {
      list.value = list.value.map(_mapChecked)
    }
  }

  const setCheckList = (arrayList) => {
    if (arrayList && !isRef(arrayList)) {
      list.value = arrayList.map(_mapChecked)
    }
  }

  const setItemChecked = (id) => {
    const item = getItemById(id)

    if (item) {
      item.checked = !item.checked
    }
  }

  return {
    list,
    allItems,
    checkedNb,
    getListNumber,
    getItemById,
    removeItem,
    resetChecked,
    setCheckList,
    setItemChecked
  }
}

export default useListWithCheck
