import { computed, ref } from 'vue'
import moment from 'moment'
import { GENDERS, LANGUAGES } from '@/datas/globals'
import { VISIBILITY_OPTIONS } from '@/datas/users'
import { PhoneTypesData } from '@/datas/addresses'
import { useCommonObjDatas } from '@/composables/useCommonObjDatas'

moment.locale('fr')

export const useComputedUserData = () => {
  const CommonObjDatas = useCommonObjDatas()
  const data = ref({})
  const files = ref({})

  const profileFieldsList = [
    'email',
    'lastName',
    'firstName',
    'gender',
    'phone',
    'title',
    'pseudo',
    'quote',
    'position',
    'description',
    'birthDate'
  ]

  const setData = (value) => {
    data.value = value
    CommonObjDatas.setData(value)
  }

  const setFiles = (value) => {
    files.value = value
  }

  const isWaiting = computed(() => {
    return data.value.state === 0 && !data.value.activatedAt
  })

  const isEnabled = computed(() => {
    return data.value.state === 1
  })

  const isDisabled = computed(() => {
    return data.value.state === 2
  })

  const isDeleted = computed(() => {
    return data.value.state === 3
  })

  const isAdmin = computed(() => {
    return (data.value.login) ? data.value.login.includes('ZBS') : false
  })

  const isOkAbout = computed(() => {
    return isEnabled.value
  })

  const isOkPresentation = computed(() => {
    return !!files.value.avatar
  })

  const isOkContactInfos = computed(() => {
    if (data.value.address) {
      return data.value.address.main !== '' && data.value.address.zipCode !== '' && data.value.address.city !== '' && !!Object.values(data.value.network).length
    } else {
      return false
    }
  })

  const isOkRoles = computed(() => {
    return false
  })

  const isOkServices = computed(() => {
    return false
  })

  const isOkSecurity = computed(() => {
    return false
  })

  const getCreationDaysDuration = computed(() => {
    const days = moment(Date.now()).diff(moment(data.value.createdAt), 'days')
    return days + ' jour' + (days > 1 ? 's' : '')
  })

  const getFormattedActivatedDate = computed(() => {
    return data.value.activatedAt ? moment(data.value.activatedAt).format('LL') : false
  })

  const getActivatedDaysDuration = computed(() => {
    const days = moment().diff(moment(data.value.activatedAt), 'days')
    return days + ' jour' + (days > 1 ? 's' : '')
  })

  const getStatusInfo = computed(() => {
    return isWaiting.value ? 'Profil inactif. Email en attente de vérification' : 'Profil actif depuis le <span class="txt-regular">' + getFormattedActivatedDate.value + '</span> soit ' + getActivatedDaysDuration.value
  })

  const getUpdatedInfo = computed(() => {
    let str = ''
    if (data.value.updatedAt) {
      str = 'Modifié le <span class="txt-regular">' + CommonObjDatas.getFormattedUpdatedDate.value + '</span>'
      if (data.value.editor) {
        str += ' par <span class="txt-regular">' + data.value.editor.firstName + ' ' + data.value.editor.lastName + '</span>'
      }
    } else {
      str = 'Cette fiche n’a jamais été modifiée'
    }
    return str
  })

  const getFormattedBirthday = computed(() => {
    return data.value.birthDate ? moment(data.value.birthDate).format('L') : false
  })

  const getAge = computed(() => {
    return data.value.birthDate ? moment().diff(data.value.birthDate, 'years') + ' ans' : false
  })

  const getLangLabel = computed(() => {
    return data.value.lang ? LANGUAGES[data.value.lang.toLowerCase()] : ''
  })

  const getGender = computed(() => {
    return typeof data.value.gender === 'number' ? GENDERS[data.value.gender] : ''
  })

  const getPhoneTypeLabel = computed(() => {
    const f = PhoneTypesData.filter((item) => item.value === data.value.phoneType)
    return f.length ? f[0].title : ''
  })

  const getAltPhoneTypeLabel = computed(() => {
    const f = PhoneTypesData.filter((item) => item.value === data.value.altPhoneType)
    return f.length ? f[0].title : ''
  })

  const getPercentageCompletion = computed(() => {
    const entries = Object.entries(data.value).filter((item) => profileFieldsList.includes(item[0]))
    const emptyFields = entries.filter((item) => {
      return item[1] && item[1] !== ''
    })
    return Math.round(emptyFields.length / entries.length * 100).toString()
  })

  const getVisibilityLabel = computed(() => {
    const fa = VISIBILITY_OPTIONS.filter((item) => item.value === data.value.visibility)
    return fa.length ? fa[0].label : ''
  })

  return {
    ...CommonObjDatas,
    setData,
    setFiles,
    isWaiting,
    isEnabled,
    isDisabled,
    isDeleted,
    isAdmin,
    isOkAbout,
    isOkPresentation,
    isOkContactInfos,
    isOkRoles,
    isOkServices,
    isOkSecurity,
    getCreationDaysDuration,
    getFormattedActivatedDate,
    getActivatedDaysDuration,
    getStatusInfo,
    getUpdatedInfo,
    getFormattedBirthday,
    getAge,
    getLangLabel,
    getGender,
    getPercentageCompletion,
    getVisibilityLabel,
    getPhoneTypeLabel,
    getAltPhoneTypeLabel
  }
}
